const Config = {
    Services: {
        Analytics: {
            TrackingId: process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID
        },
        Messaging: {
            Url: process.env.GATSBY_VI_API_PATH
        },
        Recaptcha: {
            Key: process.env.GATSBY_RECAPTCHA_SITE_KEY
        },
        ServiceRoutes: {
            featuredServices: "/services/interior-design-consultation",
            designAndDecorating: "/services/drapery-valance-design-furniture-upholstery",
            repairService: "/services/blind-shade-shutter-repair-window-covering-alternation",
            installationService: "/services/window-covering-automation-installation",
            serviceInformation: "/services/service-information-free-estimates"
        },
        ProductRoutes: {
            featuredProducts: "/products/alustra-privacy-sheers-shades",
            hunterDouglasWindowFashions: "/products/hunter-douglas-horizontal-vertical-blinds-window-panels",
            bedBathAndKitchen: "/products/bed-bath-kitchen",
            patioAwningsShadesAndDrapers: "/products/patio-porch-shades-drapers-solar-screen",
            windowTreatments: "/products/windows-cornices-draperies-valances-hardware",
            homeDecor: "/products/fabrics-roman-austrian-balloon-shades"
        }
    },
    // SocialMedia: {
    //     Facebook: {
    //         AppId: '1701990996532852'
    //     }
    // },
    Site: {
        Name: "Viktoria Interior"
    }
    ,
    SocialMediasReferences: {
        twitter: "",
        linkedin: "",
        instagram: "",
        facebook: "https://www.facebook.com/viktoriainterior/"
    }
}

export default Config;