import React, { Component } from 'react'
import ScrollLink from './scroll-link';
import Logo from '../images/icons/logo.svg';
import MenuCloseIcon from '../images/icons/close.svg';
import MenuDefaultIcon from '../images/icons/menu.svg';
import ViktoriaLink from '../components/viktorialink';
import SelectedArrowDownIcon from '../images/icons/li-down-arrow-selected.svg';
import NotselectedArrowDownIcon from '../images/icons/li-down-arrow-notselected.svg';
import Config from '../config';

class Navigation extends Component {

    state = {
        onClickNavMobileBtn: false,
        openProductSubmenu: false,
        openServiceSubmenu: false
    }

    onNavMobileBtnClick = () => {
        this.setState({
            onClickNavMobileBtn: !this.state.onClickNavMobileBtn

        })
    }

    onClickopenServiceSubmenu = () => {
        this.setState({ openServiceSubmenu: !this.state.openServiceSubmenu });
    }

    onClickopenProductSubmenu = () => {
        this.setState({ openProductSubmenu: !this.state.openProductSubmenu });
    }

    render() {
        let defaultOnNavMobileBtnClick = "menu-btn ";
        const productRoutes = Config.Services.ProductRoutes;
        const serviceRoutes = Config.Services.ServiceRoutes; 

        return (
            <div className="nav-component">
                <nav id="nav" className="nav-container">
                    <div className="container">

                        <div className={this.state.onClickNavMobileBtn ? "nav-bar nav-open" : "nav-bar"}>
                        <div className="row">
                                <div className="col-7 col-lg-3 col-md-5 ">
                                    <ScrollLink to="/">
                                        <img className="logo" alt="Viktoria Interior" src={Logo} />
                                    </ScrollLink>
                                </div>
                                <div className="text-right  offset-2 col-3 offset-md-4 col-3 d-block d-block d-lg-none">
                                    <button onClick={this.onNavMobileBtnClick} type="button"
                                        className={this.state.onClickNavMobileBtn ?
                                            defaultOnNavMobileBtnClick + "active" :
                                            defaultOnNavMobileBtnClick
                                        } >
                                        <img src={this.state.onClickNavMobileBtn ? MenuCloseIcon : MenuDefaultIcon} alt="Menu" className="menu-icon" />
                                    </button>
                                </div>
                                <div className="text-left text-lg-right col-lg-9 col-md-12 col-sm-12 pr-0">
                                    <ul className="mb-0 mt-4">
                                        <li className="mr-4">
                                            <ViktoriaLink exact="true" activeClassName="active" to="/">HOME</ViktoriaLink>
                                        </li>
                                        <li className="mr-4 ">
                                            <ul>
                                                <li>
                                                    <ul>
                                                        <li>
                                                            <ViktoriaLink to="/products">
                                                                PRODUCTS
                                                            </ViktoriaLink>
                                                        </li>
                                                        <li className="d-block d-lg-none">
                                                            <button type="button" className="submenu-button" onClick={this.onClickopenProductSubmenu} >
                                                                <img src={this.state.openProductSubmenu ? SelectedArrowDownIcon : NotselectedArrowDownIcon} alt="dropdown arrow" />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </li>
                                                {this.state.openProductSubmenu && (<li>
                                                    <ul>
                                                        <li>
                                                            <ul className="submenu">
                                                                <li>
                                                                    <ViktoriaLink to={productRoutes.featuredProducts}>
                                                                        FEATURED PRODUCTS
                                                                    </ViktoriaLink>
                                                                </li>
                                                                <li>
                                                                    <ViktoriaLink to={productRoutes.windowTreatments}>
                                                                        WINDOW FASHION
                                                                    </ViktoriaLink>
                                                                </li>
                                                                <li>
                                                                    <ViktoriaLink to={productRoutes.bedBathAndKitchen}>
                                                                        BED, BATH AND KITCHEN
                                                                    </ViktoriaLink>
                                                                </li>
                                                                <li>
                                                                    <ViktoriaLink to={productRoutes.homeDecor}>
                                                                        HOME DECOR
                                                                    </ViktoriaLink>
                                                                </li>
                                                                <li>
                                                                    <ViktoriaLink to={productRoutes.patioAwningsShadesAndDrapers}>
                                                                        PATIO AWNINGS, SHADES AND DRAPERS
                                                                    </ViktoriaLink>
                                                                </li>
                                                                <li>
                                                                    <ViktoriaLink to={productRoutes.windowTreatments}>
                                                                        WINDOW TREATMENTS
                                                                    </ViktoriaLink>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                )}
                                            </ul>
                                        </li>
                                        <li className="mr-4 ">
                                            <ul>
                                                <li>
                                                    <ul>
                                                        <li>
                                                            <ViktoriaLink to="/services">
                                                                SERVICES
                                                            </ViktoriaLink>
                                                        </li>
                                                        <li className="d-block d-lg-none">
                                                            <button type="button" className="submenu-button" onClick={this.onClickopenServiceSubmenu}>
                                                                <img src={this.state.openServiceSubmenu ? SelectedArrowDownIcon : NotselectedArrowDownIcon} alt="dropdown arrow" />
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    {this.state.openServiceSubmenu && (
                                                        <ul className="submenu">
                                                            <li>
                                                                <ViktoriaLink to={serviceRoutes.featuredServices}>
                                                                    FEATURED SERVICES
                                                                </ViktoriaLink>
                                                            </li>
                                                            <li>
                                                                <ViktoriaLink to={serviceRoutes.designAndDecorating}>
                                                                    DESIGN AND DECORATING
                                                                </ViktoriaLink>
                                                            </li>
                                                            <li>
                                                                <ViktoriaLink to={serviceRoutes.repairService}>
                                                                    REPAIR SERVICES
                                                                </ViktoriaLink>
                                                            </li>
                                                            <li>
                                                                <ViktoriaLink to={serviceRoutes.installationService}>
                                                                    INSTALLATION SERVICES
                                                                </ViktoriaLink>
                                                            </li>
                                                            <li>
                                                                <ViktoriaLink to={serviceRoutes.serviceInformation}>
                                                                    SERVICE INFORMATION
                                                                </ViktoriaLink>
                                                            </li>
                                                        </ul>
                                                    )}
                                                </li>

                                            </ul>
                                        </li>
                                        <li className="mr-4">
                                            <ViktoriaLink exact="true" activeClassName="active" to="/about">ABOUT US</ViktoriaLink>
                                        </li>
                                        <li className="mr-4">
                                            <ViktoriaLink exact="true" activeClassName="active" to="/contact">CONTACT US</ViktoriaLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav >
            </div >
        )
    }
}

export default Navigation;