import React, { Component } from 'react';
//import Config from '../config';

class Footer extends Component {
    render() {
        return (
            <footer className="footer-component">
                <div className="container">
                    <hr />
                    <div className="row pt-4 pb-5">
                        <div className="col-12 text-center col-md-6 text-md-left">
                            <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.viktoriainterior.com%2F&width=450&layout=standard&action=like&size=small&show_faces=true&share=true&height=80&appId=820306248042966"
                                width="450" height="80" style={{ border: 'none', overflow: 'hidden' }} scrolling="no"
                                title="Facebook Likes"
                                frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                            {/* <ul className="list-inline">
                                <li className="list-inline-item">
                                    <a href={Config.SocialMediasReferences.linkedin}>
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href={Config.SocialMediasReferences.instagram} target="_new">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li className="list-inline-item">
                                    <a href={Config.SocialMediasReferences.facebook} target="_new">
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                        <div className="col-12 text-center text-md-right col-md-6">
                            <p>&copy; Copyright {new Date().getFullYear()} <a href="/">Viktoria Interior</a> - All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;