import React from 'react';
import { Helmet } from "react-helmet";
import PreviewImage from '../images/photos/our-story-about-us.png';

class HelmetEx extends React.Component {

    constructor(props) {
        super(props);

        this._webAddress = process.env.GATSBY_VI_WEB_ADDRESS;
        this._metadata = {};
        const $this = this;
        this.state = { urlOrigin: '' }

        React.Children.forEach(props.children, (element, index) => {
            if (element && element.props && element.props.metadata && element.props.children) {
                $this._metadata[element.props.metadata] = element.props.children;
            }
        });
    }

    componentDidMount() {
        this.setState({ urlOrigin: window.location.origin });
    }

    render = () => {
        return (
            <Helmet>
                <title>{this._metadata.title}</title>
                <link rel="alternate" href={this._metadata.path} hreflang="en-us" />
                <html lang="en" href={this._metadata.path} />
                <meta name="description" content={this._metadata.description} />
                <meta name="keywords" content={this._metadata.keywords} />
                <meta property="og:title" content={this._metadata.title} />
                <meta property="og:description" content={this._metadata.description} />
                <meta property="og:url" content={this._webAddress + (this._metadata.path || '')} />
                <meta property="og:image" content={this._webAddress + (this._metadata.image || PreviewImage)} />
                <meta property="og:type" content={this._metadata.type || "website"} />
                <meta name="twitter:title" content={this._metadata.title} />
                <meta name="twitter:description" content={this._metadata.description} />
                {/* <meta property="fb:app_id" content={Config.SocialMedia.Facebook.AppId} /> */}
                ({this._metadata.canonicalurl && <link rel="canonical" href={this._webAddress + (this._metadata.canonicalurl || '')} />})
                {/* {this.state.urlOrigin !== "" && (<meta name="robots" content="noindex" />)} */}
            </Helmet>
        );
    }
}

export default HelmetEx;
