import React from 'react';
import { Link } from 'gatsby';
import { animateScroll } from 'react-scroll';

class ScrollNavLink extends React.Component {
    
    handleClick = (event) => {
        animateScroll.scrollToTop();
    }

    render = () => {
        return (
            <Link {...this.props} onClick={this.handleClick}>{this.props.children}</Link>
        );
    }
}

export default ScrollNavLink;