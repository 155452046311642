/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { StaticQuery, graphql } from "gatsby"
import Footer from './footer';
import Navigation from './navigation';
import '../styles/portal.scss';
// import "~slick-carousel/slick/slick.css"; 
// import "~/slick/slick-theme.css";

const Layout = ({ children }) => (
  <div className="layout-component">
    <Navigation />
    <main>{children}</main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
