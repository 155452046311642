import React from 'react';
import { Link } from "gatsby";
import { animateScroll } from 'react-scroll';

class ScrollLink extends React.Component {
    
    handleClick = () => {
        animateScroll.scrollToTop();
    }

    render = () => {
        return (
            <Link {...this.props} onClick={this.handleClick}>{this.props.children}</Link>
        );
    }
}

export default ScrollLink;